import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { hasChildren } from "./utils";
import Tag from "../assets/new.png";
import Tims from "../assets/TIMS.svg";
import FullScreenDialog from "../Modal/Modal";
import "./Side.css";

export default function Side({ items }) {
  const handleHome = () => {
    window.location.href = "";
  };
  return (
    <div className="outer-container">
      <div className="side-header flex-center" onClick={handleHome}>
        <img src={Tims} alt="test" width="140" height="70" />
      </div>
      <div className="sidebar">
        <Submenu items={items} />
      </div>
    </div>
  );
}

export function Submenu({ items }) {
  return items?.map((item, key) => <MenuItem key={key} item={item} />);
}

const MenuItem = ({ item }) => {
  const Component =
    hasChildren(item) && item.dropDown ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState();

  const handleClickOpen = (type, url, title) => {
    if (type !== "header1") {
      const fileExtension = url.split(".").pop().toLowerCase();

      if (fileExtension === "pdf") {
        setUrl(url);
        setOpen(true);
      } else {
        const link = document.createElement("a");
        link.href = `${process.env.PUBLIC_URL + url}`;
        link.download = title;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  let newDate = new Date();
  let prevDate = new Date(item.publishedDate);
  var dateCheck =
    newDate.getMonth() + 1 === prevDate.getMonth() + 1 &&
    newDate.getFullYear() === prevDate.getFullYear() &&
    Math.abs(newDate.getDate() - prevDate.getDate()) < 5
      ? true
      : false;

  return (
    <>
      <ListItem
        button
        className={`${item.type === "header1" && "header1"} ${
          item.type === "header3" && "subList"
        } ${item.type === "header4" && "subListLight"} ${
          item.type === "header5" && "subListDark"
        }`}
        onClick={() => handleClickOpen(item.type, item.url, item.title)}
        component="a"
        href="#"
      >
        <ListItemText primary={item.title} />
        {dateCheck === true ? (
          <img src={Tag} alt="test" width="30" height="30" />
        ) : (
          ""
        )}
      </ListItem>
      <List component="div" disablePadding>
        {item.subItems.map((child, key) => (
          <MenuItem key={key} item={child} />
        ))}
      </List>
      {open && (
        <FullScreenDialog open={open} handleClose={handleClose} url={url} />
      )}
    </>
  );
};

const MultiLevel = ({ item }) => {
  const { subItems: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        className={`${item.type === "header3" && "subList"} ${
          item.type === "header4" && "subListLight"
        } ${item.type === "header5" && "subListDark"} `}
        button
        onClick={handleClick}
      >
        <ListItemText primary={item.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
