import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './Boxe.css'

export default function Boxe(props) {
    return (
        <Card sx={{
            minWidth: 275, width: '105vh', border: '1px solid #85b1ce', margin: '0px 1.25rem 1.25rem 0px', borderRadius: 0, background: '#1576bd', color: 'white', height: '10.1rem',
            '@media (max-height: 768px)': {
                height: '10.2rem'
            },
        }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props?.data?.heading,
                        }}
                    ></div>
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '1rem' }} component="div">
                    <div
                        className='word-content'
                        dangerouslySetInnerHTML={{
                            __html: props?.data?.body,
                        }}
                    ></div>
                </Typography>
                <div
                    className='author-content'
                    dangerouslySetInnerHTML={{
                        __html: props?.data?.author,
                    }}
                ></div>
            </CardContent>
        </Card>
    );
}