export function hasChildren(item) {
  const { subItems: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.length > 0) {
    return true;
  }

  return false;
}
