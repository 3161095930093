import React, { useState, useEffect } from "react";
import Side from "../sideFolder/side.js";
import MainContent from "../Main Page/MainContent.js";
import "./Homepage.css";
import bar from "../assets/bar.svg";

const Homepage = () => {
  const [data, setData] = useState([]);
  const [isSidebarVisible, setSidebarVisible] = useState(true);

  const updateSize = () => {
    if (isSidebarVisible && window.innerWidth > 750) {
      setSidebarVisible(true);
    }
  };
  window.addEventListener("resize", updateSize);
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  const getData = () => {
    fetch("TIMS.json", {
      headers: {
        "Content-Type": "application/json",

        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      });
  };

  useEffect(() => {
    getData();
  }, [isSidebarVisible]);

  return (
    <div
      className={`App ${
        isSidebarVisible ? "sidebar-visible" : "sidebar-hidden"
      }`}
    >
      {isSidebarVisible && <Side items={data.timsContents} />}

      {data.staticContent && (
        <MainContent isSidebarVisible={isSidebarVisible} items={data} />
      )}
      <img
        src={bar}
        alt="test"
        width="30"
        height="30"
        className="toggle-button"
        onClick={toggleSidebar}
      />
    </div>
  );
};

export default Homepage;
