import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tag from "../assets/new.png";
import FullScreenDialog from "../Modal/Modal";

export default function ListItems(props) {
    const { newItems } = props;
    const [open, setOpen] = React.useState(false);
    const [url, setUrl] = React.useState();

    const handleClickOpen = (type, url) => {
        if (type !== "header1") {
            setUrl(url);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <List sx={{ padding: '0px' }}>
                    {newItems?.map((value, index) =>
                        <ListItem disablePadding>
                            <ListItemButton sx={{ padding: '5px 25px 5px 0px' }} component="span" href="#simple-list" onClick={() => handleClickOpen(value.type, value.url)}>
                                <ListItemText primary={value.title} />
                                <img src={Tag} alt="test" width="30" height="30" />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Box>
            {open && (
                <FullScreenDialog open={open} handleClose={handleClose} url={url} />
            )}
        </>
    );
}
