import React from "react";
import "./MainContent.css";
import Slider from "../Slider/Slider";
import Box from "@mui/material/Box";
import tims from "../assets/Picture6.png";
import placeholderPoster from "../assets/Picture6.png";
import pic from "../assets/Trenser-Logo.png";
import Logout from "../assets/logout.png";
import FullScreenDialog from "../Modal/Modal.jsx";
import Tabs from "../Tabs/Tabs.jsx";
import Cards from "../Cards/Cards.jsx";
import Boxe from "../Boxes/Boxe.jsx";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const MainContent = (props) => {
  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState();

  const logout = () => {
    localStorage.removeItem("credential");
    window.location.href = "";
  };

  const handleEmail = () => {
    window.open(
      "mailto:pip@trenser.com?subject=Subject&body=Body%20goes%20here"
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = [];
  const dataBuilder = (arg) => {
    arg.map((value) => {
      if (value.subItems.length > 0) {
        dataBuilder(value.subItems);
      }
      if(value.title !== 'Process' && value.title !== 'Templates'){
      data.push({
        id: value.title,
        name: value.title,
        tag: value.tags,
        url: value.url,
      });
    }
    });
  };

  dataBuilder(props.items.timsContents);

  const handleClick = (result) => {
    handleClickOpen();
    setRecord(result);
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left", zIndex: 9999 }}>
          {item.name}
        </span>
      </>
    );
  };

  return (
    <>
      <div className={`main ${props.isSidebarVisible ? "sidebar-none" : ""}`}>
        <div className="main-content box1 flex-center position-relative flex-row justify-content-between pb-1">
          <div className="d-flex align-items-center w-100 mx-2">
            <img
              className="logo"
              src={pic}
              alt="Logo"
              width="140"
              height="30"
            />
            <div className="mt-header">
              <ReactSearchAutocomplete
                items={data}
                onSelect={handleClick}
                autoFocus
                formatResult={formatResult}
                fuseOptions={{ keys: ["name", "tag"] }}
                resultStringKeyName="name"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="position-absolute end-0 top-25 mx-2 user-info button">
            <img
              className="logout-img"
              src={Logout}
              alt="test"
              height="25"
            />
            <div className="logout" onClick={logout}>
              Log out
            </div>
          </div>
        </div>
        <div className="box2 flex-center fs-6 fw-bolder">
          <div
            // className="marquee"
            dangerouslySetInnerHTML={{
              __html: props.items.staticContent.suggestionText,
            }}
            onClick={handleEmail}
          ></div>
        </div>
        <div className="content-box">
          <div className="box3 flex-center">
            <Slider
              slides={props?.items?.staticContent.trenserTimsBanner}
              staticPoster={tims}
              placeholderPoster={placeholderPoster}
            />
          </div>
          <div
            className="box4"
            dangerouslySetInnerHTML={{
              __html: props.items.staticContent.heading,
            }}
          ></div>
          <div className="box6">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: "20px",
              }}
            >
              <Cards data={props?.items?.staticContent?.qualityCards} />
              <Cards data={props?.items?.staticContent?.securityCards} />
              <Cards data={props?.items?.staticContent?.informationCard} />
              <Cards data={props?.items?.staticContent?.objectiveCard} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
              }}
            >
              <Tabs
                tabData={props?.items?.staticContent?.tab1}
                newItems={props?.items?.timsContents}
              />
              <Boxe data={props?.items?.staticContent?.wordCard} />
            </Box>
          </div>
        </div>
      </div>
      {open && (
        <FullScreenDialog
          open={open}
          handleClose={handleClose}
          url={record.url}
        />
      )}
    </>
  );
};

export default MainContent;
