import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import API_URL from '../config'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#315496 !important",
    color: theme.palette.common.white,
    fontFamily: 'inherit !important',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'inherit !important',
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Leaderboard = () => {

  const [leaderboardData, setLeaderboardData] = useState([]);
  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const fetchLeaderboardData = () => {
    const timestamp = new Date().getTime();
    const url = `${API_URL}?timestamp=${timestamp}`
    const reqParams = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({email: localStorage.getItem('email')})
    }
    fetch(url, reqParams)
      .then(response => response.json())
      .then(json => setLeaderboardData(json))
      .catch(error => console.error(error));
  }
  return (
    <Paper sx={{
      width: '100%', display: 'grid', height: '25rem',
      '@media (max-height: 768px)': {
        height: "31rem"
      }
    }}>
      {leaderboardData.length > 0 ? <TableContainer >
        <Table size='small' sx={{
          height: '25rem',
          '@media (max-height: 768px)': {
            height: '31rem'
          },
          display: 'block',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '10px',
            backgroundColor: '#FFFFFF',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CCCCCC',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#AAAAAA',
            borderRadius: '10px',
          },
        }}>
          <TableHead style={{ position: 'sticky !important' }}>
            <TableRow>
              <StyledTableCell width={100}>Position</StyledTableCell>
              <StyledTableCell width={300} align="center" >Employee ID</StyledTableCell>
              <StyledTableCell width={400} align="center" >Name</StyledTableCell>
              <StyledTableCell width={300} align="center" >Points</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboardData?.map((row, index) => (
              <StyledTableRow key={row.name} >
                <StyledTableCell component="th" scope="row">
                  {row.position}
                </StyledTableCell>
                <StyledTableCell align="center">{row.emp_id}</StyledTableCell>
                <StyledTableCell align="center">{row.name}</StyledTableCell>
                <StyledTableCell align="center">{row.points}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> : <CircularProgress sx={{ alignSelf: 'center', justifySelf: 'center', position: 'relative' }} />}
    </Paper>
  );
}

export default Leaderboard
