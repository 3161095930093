import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function Cards(props) {
    return (
        <Card sx={{ minWidth: 275, width: '100%', border: '1px solid #85b1ce', margin: '0px 0px 20px 0px', background: '#dae1f3', maxHeight: '300px', borderRadius: 0, overflow: 'scroll' }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props?.data?.heading,
                        }}
                    ></div>
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '16px' }} component="div">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props?.data?.body,
                        }}
                    ></div>
                </Typography>
            </CardContent>
        </Card>
    );
}